import { useTranslation } from 'react-i18next'
import './language-menu.scss'

interface LanguageMenuProps {
    open: boolean
    onItemClick: (lng: string) => void
}

export const LanguageMenu = (props: LanguageMenuProps) => {
    const { t, i18n } = useTranslation()

    return (
        <div className={`language-menu ${props.open ? `` : `hidden`}`}>
            <img alt="" className="bubble" src="/image/ic_bubble_indicator.svg" />

            <ul className="floating-menu-bar">
                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('en')}>
                    <label className={`floating-menu-bar-item-title ${i18n.language.includes('en') ? `active` : ``}`}>{t('header_langugage_en_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('hu')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('hu') ? `active` : ``}`}>{t('header_langugage_hu_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('hr')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('hr') ? `active` : ``}`}>{t('header_langugage_hr_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('mne')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('mne') ? `active` : ``}`}>{t('header_langugage_mne_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('sl')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('sl') ? `active` : ``}`}>{t('header_langugage_sl_button')}</label>
                </li>

                <li className={`floating-menu-bar-item`} onClick={() => props.onItemClick('bg')}>
                    <label className={`floating-menu-bar-item-title  ${i18n.language.includes('bg') ? `active` : ``}`}>{t('header_langugage_bg_button')}</label>
                </li>
            </ul>
        </div>
    )
}
